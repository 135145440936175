@import '../../../assets/variables';

.about {
  position: relative;
  height: 100vh;
  padding: 100px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;

  &.transition-enter {
    opacity: 0;
  }
  &.transition-enter-active {
    opacity: 1;
    transition: opacity .5s .5s;
  }
  &.transition-exit {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 1;
  }
  &.transition-exit.transition-exit-active {
    opacity: 0;
    transition: opacity .5s;
  }

  .back {
    color: $white;
    font-size: 1.2em;
    position: absolute;
    top: 50px;
    left: 20%;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: margin-left .3s;

    svg {
      margin-right: 8px;
      transition: margin .3s;
    }

    &:hover {
      svg { margin-left: -6px; margin-right: 12px; }
    }
  }

  .profile-picture {
    width: 300px;
    margin-right: 20px;
  }

  .about-content {
    width: 580px;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .about-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1, h2 {
    display: inline-block;
    font-family: 'Inconsolata', sans-serif;
    font-weight: 300;
    background-color: $black;
    color: $white;
    padding: 3px;
  }

  h1 { font-size: 2.3em; margin-right: 8px; }
  h2 { font-size: 2em; background-color: $black-1; color: $white-1; }

  p {
    background-color: $black-1;
    padding: 12px;
    color: $white-1;
    margin-top: 12px;
  }

  h3 {
    margin: 16px 0 12px 0;
    color: $white;
    background-color: $black;
    display: inline-block;
  }

  .title-description-container {
    align-self: flex-end;
  }

  .tech-list {
    list-style-type: none;
    margin: 0;
    padding: 12px;

    .tech-item {
      display: inline-flex;
      align-items: center;
      border-radius: 4px;
      padding: 6px;
      margin: 8px 8px 0 0;

      svg {
        margin-right: 6px;
      }

      &.react {
        color: #61dafb;
        background-color: #282c34;
      }

      &.dotnet {
        background-color: #512BD4;
        color: $white-1;
      }

      &.redux {
        background-color: #764abc;
        color: $white;
      }

      &.node {
        background-color: #333;
        color: #689F63;
      }
      &.docker {
        background-color: #384d54;
        color: #0db7ed;
      }
      &.k8s {
        background-color: #326ce5;
        color: $white;
      }
    }
  }

  .button-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;

    .big-button {
      display: block;
      position: relative;
      margin: 0 16px;
      text-decoration: none;
      color: #f5f5f5;
      z-index: 3;

      &::before, &::after {
        content: '';
        position: absolute;
        left: -8px;
        top: 8px;
        width: 100%;
        height: 100%;
        z-index: -1;
        transform: translate(0, 0);
        transition: transform .3s;
      }

      &::before { background-color: $black-1; }
      &::after { 
        background-color: transparent; 
        opacity: 0; 
        transition: transform .3s, opacity .3s;
        z-index: -2;
        -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
        box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
      }

      .button-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 24px;
        left: 8px;
        top: -8px;
        z-index: -1;
        transform: translate(0, 0);
        transition: transform .3s, background-color .3s;
      }

      &:hover {
        &::before {
          transform: translate(-6px, 6px);
        }
        &::after {
          transform: translate(-6px, 6px);
          opacity: 1;
        }
        .button-text {
          transform: translate(6px, -6px);
        }
      }

      svg {
        font-size: 2em;
        margin: 10px 0;
      }

      &.github {
        .button-text {
          background-color: scale-color(#6e5494, $lightness: -10%);

          &:hover {
            background-color: #6e5494;
          }
        }
      }
      &.email {
        .button-text {
          background-color: scale-color($color-blue, $lightness: -10%);

          &:hover {
            background-color: $color-blue;
          }
        }
      }
      &.portfolio {
        .button-text {
          background-color: scale-color(#58B19F, $lightness: -10%);

          &:hover {
            background-color: #58B19F;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .about {
    padding: 40px 0;

    .back {
      left: 20px;
      top: 20px;
    }

    h2 { margin-top: 8px; }
    .profile-picture { display: none; }
    .about-content {
      width: 100%;
      padding: 12px;
    }

    .button-container {
      display: flex;
      flex-direction: column;

      .big-button {
        margin-bottom: 12px;

        .button-text {
          padding: 8px 12px;
        }
      }
    }
  }
}