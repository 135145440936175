@import '../../../../assets/variables';

.photo-grid {
  margin: 0 20px 20px 20px;
  max-width: 100%;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;

  .grid-container {
    display: flex;
    width: 100%;
    height: 33.3%;
    max-height: 33.3%;
    justify-content: space-between;
    margin-top: 10px;

    .photo {
      height: 100%;
      flex: 0 0 calc(33.3% - 5px);
    }

    &:not(.featured-container) {
      .photo {
        &:nth-child(2n) {
          flex: 0 0 calc(33.3% - 10px);
        }
      }
    }
  }

  .featured-container {
    flex-flow: column wrap;
    height: 66.6%;
    max-height: 66.6%;

    .photo {
      flex: 0 0 calc(50% - 5px);
      width: calc(33.3% - 5px);

      &.featured {
        flex: 0 0 100%;
        width: calc(66.6% - 5px);
        margin-right: 10px;

        &.right {
          margin-right: 0;
          margin-left: 10px;
        }
      }
    }
  }

  .photo {
    position: relative;
    margin: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;

    .underline {
      position: absolute;
      left: 50%;
      bottom: 0;
      height: 4px;
      width: 50%;
      transition: transform .3s;
      transform: scaleX(0);
      transform-origin: left center;
      z-index: 5;

      &.first { background: linear-gradient(to right, $color-blue, $color-green); }
      &.last { background: linear-gradient(to right, $color-blue, $color-purple-darker); }
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      box-shadow: inset 0 0 20px 10px rgba(0,0,0,.8);
      transition: opacity .3s;
    }

    &:hover {
      .first {
        transform: scaleX(-1);
      }
      .last {
        transform: scaleX(1); 
      }
      &::after {
        opacity: 1;
      }
    }
    
    img {
      position: relative;
      object-fit: cover;
      height: 100%;
      width: 100%;
      transform: translate(-100%, 0);
      animation: slide-in .3s forwards;

      &.flip-animation {
        transform: translate(100%, 0);
        animation: slide-in-flip .3s forwards;
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 20px 20px black;
        top: 0;
        left: 0;
      }
    }

    .cover {
      width: 100%;
      height: 100%;
      background-color: $black;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-100%, 0);
      animation: slide-in-out .4s forwards;

      &.flip-animation {
        transform: translate(100%, 0);
        animation: slide-in-out-flip .4s forwards;
      }
    }
  }

  &.transitioning {
    .photo {
      img {
        animation: slide-out .4s forwards;

        //&.flip-animation { animation: slide-out-flip .4s forwards; }
      }
    }
  }
}

@keyframes slide-in {
  from { transform: translate(-100%, 0); }
  to { transform: translate(0, 0); }
}

@keyframes slide-in-flip {
  from { transform: translate(100%, 0); }
  to { transform: translate(0, 0); }
}

@keyframes slide-in-out {
  0% {
    transform: translate(-100%, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}

@keyframes slide-in-out-flip {
  0% {
    transform: translate(100%, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

@keyframes slide-out {
  from { transform: translate(0,0); }
  to { transform: translate(-100%, 0); }
}

@keyframes slide-out-flip {
  from { transform: translate(0,0); }
  to { transform: translate(100%, 0); }
}

@media only screen and (max-width: 700px) {
  .photo-grid {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    max-height: calc(100vh - 160px);
    margin: 0 0 0 10px;

    .photo { 
      width: 90%;
      margin-bottom: 10px;
    }

    .grid-container { 
      height: auto; max-height: initial;
      margin: 0;

      .photo { width: 100%; flex-basis: 100%; }
    }

    .featured-container {
      margin: 0;
      .photo { 
        width: 100%;
        
        &.featured {
          width: 100%;

          &.right {
            margin-left: 0;
          }
        }
      }
    }
  }
}