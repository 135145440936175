@import '../../assets/variables';

.background-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  box-shadow: inset 0 0 10px 10px black;
  opacity: 0;
  animation: show 1s .5s forwards;
}

.background {
  position: absolute;
  z-index: 0;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  background: linear-gradient(to bottom, scale-color(#8e44ad, $lightness: -50%), scale-color(#2980b9, $lightness: -50%));
  animation: rotate-color 8s linear infinite;
  transition: opacity .5s;

  &::after {
    content: "";
    position: absolute;
    left:0; top: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to top, scale-color(#5f27cd, $lightness: -50%), scale-color(#341f97, $lightness: -50%));
    -webkit-mask-image: linear-gradient(to left, white, transparent);
    mask-image: linear-gradient(to left, white, transparent);
    animation: rotate-color 8s linear infinite;
    transition: background 1s;
  }

  &.hide {
    opacity: 0;
  }
}

.poly-container-main {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  transition: opacity .5s;

  .poly-background {
    width: 100%;
    height: 100%;
    line { stroke: black; stroke-width: 1px; }
    .hue-1 { fill: scale-color(#2f3640, $lightness: -70%); }
    .hue-2 { fill: scale-color(#2f3640, $lightness: -60%); }
    .hue-3 { fill: scale-color(#2f3640, $lightness: -50%); }
    .hue-4 { fill: scale-color(#2f3640, $lightness: -40%); }

    // polygon {
    //   transform: scale(.95);
    //   transform-origin: center center;
    //   transform-box: fill-box;
    // }

    .mask-rect {
      fill: linear-gradient(to top right, #050507, #2f3640, #1a1a1a);
    }
  }

  // .block-rect {
  //   fill: white;
  //   opacity: 1;
  //   transition: opacity .5s;
  // }

  &.hide-svg {
    opacity: 0;
  }
}

#lineMaskRectGradient {
  --color-stop-1: #{scale-color(#2f3640, $lightness: -80%)};
  --color-stop-2: #{scale-color(#2f3640, $lightness: -60%)};
  --color-stop-3: #{scale-color(#2f3640, $lightness: -80%)};
  --color-stop-4: #{scale-color(#2f3640, $lightness: -85%)};
  // --color-stop-1: #{scale-color(#2f3640, $lightness: -40%)};
  // --color-stop-2: #{scale-color(#2f3640, $lightness: -40%)};
  // --color-stop-3: #{scale-color(#2f3640, $lightness: -40%)};
  // --color-stop-4: #{scale-color(#2f3640, $lightness: -45%)};

  &.test {
    --color-stop-1: #{$white};
    --color-stop-2: #{$white-1};
    --color-stop-3: #{$white};
  }
}

@keyframes rotate-color {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}