$white-transparent-05: rgba(255,255,255,.05);
$white-transparent-10: rgba(255,255,255,.1);
$white-transparent-20: rgba(255,255,255,.2);
$white-transparent-30: rgba(255,255,255,.3);
$white-transparent-40: rgba(255,255,255,.4);
$white-transparent-50: rgba(255,255,255,.5);

$white-navigation-color: scale-color(#dfe4ea, $lightness: -30%);
$white-navigation-color-hover: #dfe4ea;

$white: #dfe4ea;
$white-1: scale-color(#dfe4ea, $lightness: -10%);
$black: #161a1f;
$black-1: scale-color(#161a1f, $lightness: -20%);

$color-green: #38ada9;
$color-blue: #2980b9;
$color-purple: #8e44ad;
$color-purple-darker: #5f27cd;
$color-purple-darkest: #341f97;