.photography {
  display: grid;
  grid-template-columns: minmax(0, 291px) 1fr;
  grid-template-rows: calc(100vh - 100px) calc(100vh - 100px);
  height: calc(200vh - 200px);
  margin-top: 100px;
  position: relative;
  max-width: 100vw;
  transition: transform .5s, opacity .5s;
  transform: translateY(0);
  opacity: 1;

  &.transition-enter {
    opacity: 0;
    transition-delay: .5s;
  }
  &.transition-enter-active {
    opacity: 1;
  }
  &.transition-exit {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 1;
  }
  &.transition-exit-active {
    opacity: 0;
  }

  &.photo-selected {
    transform: translateY(calc(-100vh));
  }
}

@keyframes header-blink-in {
  0% {
    opacity: 0;
  }
  5% {
    opacity: .8;
  }
  15% {
    opacity: .1;
  }
  25% {
    opacity: 1;
  }
  35% {
    opacity: .1;
  }
  45% {
    opacity: .1;
  }
  // 60% {
  //   opacity: 0;
  // }
  // 45% {
  //   background-color: transparent;
  // }
  // 50% {
  //   background-color: $white-transparent-10;
  // }
  // 55% {
  //   background-color: transparent;
  // }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 700px) {
  .photography {
    grid-template-columns: 1fr;
    grid-template-rows: 60px calc(100vh - 100px) 100vh;

    &.photo-selected {
      transform: translateY(calc(-100vh - 100px));
    }
  }
}