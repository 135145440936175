@import './variables';
@import './mixins';

* { box-sizing: border-box; font-family: 'Roboto'; }

body { 
  position: relative;
  margin: 0;
  animation: body-in .5s forwards;
  background: black;
  //linear-gradient(to bottom, scale-color(#2f3640, $lightness: -90%), scale-color(#2f3640, $lightness: -40%));
  //animation: rotate 8s linear infinite;

  // &::after {
  //   content: "";
  //   position: absolute;
  //   z-index: -1;
  //   left:0; top: 0;
  //   width: 100vw;
  //   height: 100vh;
  //   background: linear-gradient(to top, scale-color(#2f3640, $lightness: -90%) , scale-color(#2f3640, $lightness: -60%));
  //   -webkit-mask-image: linear-gradient(to left, white, transparent);
  //   mask-image: linear-gradient(to left, white, transparent);
  //   animation: rotate 8s linear infinite;
  // }
}

p { margin: 0; }
h1 { font-size: 1.55em; }
h2 { font-size: 1.35em; }
h3 { font-size: 1.25em; }
h4 { font-size: 1.15em; }
p { font-size: 1em; }
button { font-size: 1em; margin: 0; outline: none; background: transparent; border: none; }
h1, h2, h3, h4 { margin: 0; }
h3 {
  &.multiply-title { 
    background-color: white; 
    mix-blend-mode: multiply;
    color: gray;
  }
}

main {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#modal-root {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.transition-container {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

::-webkit-scrollbar { 
  width: 18px;
}
::-webkit-scrollbar-track {
  border: 7px solid rgba(0,0,0,0);
  background-clip: padding-box;
  background-color: rgba(0,0,0, .1);
  border-radius: 2px;
}
::-webkit-scrollbar-thumb { 
  border: 7px solid rgba(0,0,0,0);
  background-clip: padding-box;
  background-color: $color-green;
  border-radius: 2px;
}

.depth-1 {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.depth-2 {
  -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
}

.depth-3 {
  -webkit-box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
  box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
}

.animate-entry { animation: show 1s forwards; }
.animate-entry-delayed { opacity: 0; animation: show 1s .25s forwards; }

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(10deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}

@keyframes body-in {
  from {
    background: black;
  }
  to {
    background: scale-color(#2f3640, $lightness: -90%);
  }
}