@import './assets/base';

.main-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.transition-enter-active {
  position: absolute;
  left: 0;
  top: 0;
}