.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  animation: fade-in 200ms forwards;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.loading-bar {
  width: 300px;
  background-color: gray;
  height: 2px;

  .progress {
    width: 0;
    display: block;
    height: 100%;
    background-color: white;
    transition: width 200ms linear;
  }

  &.finish {
    animation: fade-out 300ms forwards 200ms;

    .progress { width: 100%; }
  }
}

@keyframes fade-out {
  from { opacity: 1 }
  to { opacity: 0; }
}

@keyframes fade-in {
  from { opacity: 0 }
  to { opacity: 1; }
}

@media only screen and (max-width: 700px) {
  .loading-container {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    margin: 0 10px;
  }
}