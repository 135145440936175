@import '../../../../assets/variables';

.album-list {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  position: relative;
  align-self: flex-start;

  .line {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, $color-green, $color-blue, $color-purple-darker);
  }

  .album {
    margin: 0 0 0 20px;
    padding: 12px 0 12px 12px;
    cursor: pointer;
    border-left: 2px solid #555;

    h3 {
      font-size: 1.6em;
      font-family: 'Montserrat';
      text-align: center;
      display: inline-block;
      color: #888;
      position: relative;
      transition: color .3s, margin-left .3s;
    }

    &.active {
      h3 { 
        color: $white-navigation-color-hover;
        margin-left: 8px;
      }
    }

    &:hover {
      h3 { color: $white-navigation-color-hover; }
    }

    &.highlighter {
      position: absolute;
      left: 20px;
      background-color: $color-green;
      width: 2px;
      height: 57px;
      top: 0;
      padding: 0;
      margin: 0;
      border: none;
      transition: transform .2s ease-in-out;
    }
  }
}

@media only screen and (max-width: 700px) {
  .album-list {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    padding: 0;
    max-width: 100%;
    overflow-x: scroll;
    display: flex;

    .album {
      display: inline-block;
      border-left: none;
      background-color: rgba(255,255,255, .2);
      border-radius: 4px;
      margin: 0 10px; 
      padding: 8px 12px;

      h3 {
        margin-left: 0;
        font-size: 1.4em;
      }

      &.active {
        h3 {
          margin-left: 0;
        }
      }

      &.highlighter { display: none; }
    }
  }
}