@import './variables';

@mixin tech-badge($color) {
  background: linear-gradient(to right, $color, scale-color($color, $lightness: 10%));
}

@mixin range-thumb {
  -webkit-appearance: none;
  border-radius: 18px;
  width: 18px;
  height: 18px;
  background: linear-gradient(to top right, $color-purple-darker, $color-purple-darkest);
  box-shadow: -1px 1px 3px 0px rgba(0,0,0,.3);
  cursor: pointer;
  margin: -2px;
}

@mixin range-track {
  width: 100%;
  background: linear-gradient(to right, $color-green, $color-blue);
  height: 12px;
  border-radius: 4px;
}