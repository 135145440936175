@import '../../assets/variables';

nav {
  margin-left: 10px;
  position: relative;

  .mobile-menu-icon { display: none; }
  h2 { font-size: 1.7em; }

  .navigation-item {
    padding: 7px 12px 5px 20px;
    background-color: transparent;
    cursor: pointer;
    transition: background-color .3s, opacity .3s;
    display: flex;
    align-items: center;
    color: $white-1;
    overflow: hidden;

    &:hover {
      background-color: rgba(255,255,255, .2);
      transform: translateZ(0);

      .photo-icon {
        transform: translateY(-50%) scale(1.5) rotate(50deg);
      }
      .project-icon {
        transform: translateY(-50%) scale(1.5) rotate(-90deg);
      }
    }

    &.hide {
      opacity: 0;
      pointer-events: none;
    }

    &.highlight {
      position: absolute;
      left: 0;
      top: 0;
      background-color: $color-green;
      color: $color-green;
      transition: transform .3s cubic-bezier(0.075, 0.82, 0.165, 1);
      pointer-events: none;
      will-change: transform;
    }

    .navigation-icon {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 2.5em;
      top: 50%;
      transform: translateY(-50%);
      fill: rgba(255,255,255, .15);
      transition: transform .3s;
    }

    .dropdown-icon {
      margin-left: 8px;
    }
  }

  .item-list {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    list-style-type: none;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;

    &.open {
      opacity: 1;
      pointer-events: auto;
    }
    
    li { position: relative; z-index: 1; }

    .navigation-item {
      background-color: rgba(255,255,255, .5);
      color: $black;
      transform: none;

      &.highlight { 
        background-color: $color-purple-darkest;
        color: $color-purple-darkest;
      }
    }
  }

  .mobile-navigation-icon {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  nav {
    order: 3;
    padding: 8px 0;
    position: static;
    margin-left: auto;
    
    .mobile-menu-icon {
      cursor: pointer;
      display: inline-block;
      position: relative;
      color: $white;
      font-size: 2em;
    }

    .navigation-item {
      &.desktop { display: none; }
    }

    .item-list {
      width: 100vw;
      top: 100%;
      left: 0;
      height: calc(100vh - 92px);
      background-color: rgba(0,0,0, .9);

      .navigation-item {
        background-color: transparent;
        color: $white;
        padding: 14px 0 14px 20px;
        &.highlight { display: none; }
      }
    }
  }
}