@import '../../../assets/variables';

.projects {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.transition-enter {
    opacity: 0;
  }
  &.transition-enter-active {
    opacity: 1;
    transition: opacity .5s .5s;
  }
  &.transition-exit {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 1;
  }
  &.transition-exit.transition-exit-active {
    opacity: 0;
    transition: opacity .5s;
  }
}

.project {
  position: relative;
  margin: 24px;
  flex: 0 0 35%;

  .project-inner {
    background-color: rgba(255,255,255, .1);
    transition: transform .2s;
    backface-visibility: hidden;
  }

  .project-image {
    background-position: center center;
    background-size: cover;
    height: 150px;
    width: 100%;
    margin: 0;
  }

  * {
    pointer-events: none;
  }

  .title-container {
    position: relative;
    overflow: hidden;

    h2 { 
      font-size: 1.8em; 
      position: relative; 
      z-index: 2;
      color: $white-1;
      padding: 24px;
    }

    .before {
      font-size: 2.5em;
      position: absolute; 
      z-index: 1; 
      left: 50%;
      transform: translate(-50%, 0);
      top: 0;
      opacity: .2;
      padding: 10px;
      white-space: nowrap;
      text-align: center;
    }
  }

  .content-container {
    background-color: $black;
    color: $white-1;
    padding: 24px 24px 48px 24px;
    min-height: 110px;
  }

  .link-container {
    background-color: $black-1;
    display: flex;

    .link-button {
      transform: translateY(-50%);
    }
  }

  .line {
    position: absolute;
    width: 0;
    height: 4px;
    transition: width .4s, height .4s;

    &.right, &.left {
      height: 0;
      width: 4px;
    }
    &.top {
      transform: rotate(180deg);
      top: -1px;
      right:0;
      background: linear-gradient(to right, $color-blue, $color-purple);
      border-radius: 0 0 2px 2px;
    }
    &.left {
      top: 0;
      left: 0;
      border-radius: 2px 0 0 2px;
      background: linear-gradient($color-purple, $color-purple-darker);
    }
    &.right {
      bottom: 0;
      left: calc(100% - 4px);
      transform-origin: bottom left;
      border-radius: 0 2px 2px 0;
      background: linear-gradient($color-blue, $color-purple-darkest);
    }
    &.bottom {
      bottom: 0;
      left: 0;
      border-radius: 0 0 2px 2px;
      background: linear-gradient(to right, $color-purple-darker, $color-purple-darkest);
    }
  }

  &:hover{
    .top, .bottom {
      width: 100%;
    }
    .line.left {
      height: 100%;
    }
    .line.right {
      height: 100%;
    }
  }

  .link-button {
    text-decoration: none;
    color: $white-1;
    display: inline-block;
    padding: 12px 18px;
    margin-left: 24px;
    position: relative;
    pointer-events: auto;
    font-weight: bold;

    .label { 
      z-index: 2; 
      position: relative;
      display: flex;
      align-items: center;

      .type-icon {
        transition: transform .3s;
        transform: scale(0) rotate(45deg);
        margin-right: 6px;
      }

      .arrow-icon {
        margin-left: 6px;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: calc(100% - 2px);
      top:0; left: 0;
      background-color: $black;
      transition: width .3s ease-in-out;
      z-index: 1;
    }

    &:hover {
      &::before {
        width: 100%;
      }
      .type-icon {
        transform: scale(1) rotate(0deg);
      }
    }

    &.blue { background-color: $color-blue; }
    &.green { 
      background-color: $color-green;

      &:hover {
        &::before {
          -webkit-box-shadow: 0 8px 17px 2px rgba(56, 173, 169,0.14), 0 3px 14px 2px rgba(56, 173, 169,0.12), 0 5px 5px -3px rgba(56, 173, 169,0.2);
          box-shadow: 0 8px 17px 2px rgba(56, 173, 169,0.14), 0 3px 14px 2px rgba(56, 173, 169,0.12), 0 5px 5px -3px rgba(56, 173, 169,0.2);
        }
      }
    }
    &.purple { 
      background-color: $color-purple-darker;

      &:hover {
        &::before {
          -webkit-box-shadow: 0 8px 17px 2px rgba(95, 39, 205,0.14), 0 3px 14px 2px rgba(95, 39, 205,0.12), 0 5px 5px -3px rgba(95, 39, 205,0.2);
          box-shadow: 0 8px 17px 2px rgba(95, 39, 205,0.14), 0 3px 14px 2px rgba(95, 39, 205,0.12), 0 5px 5px -3px rgba(95, 39, 205,0.2);
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .projects {
    flex-direction: column;
    justify-content: center;
  }

  .project {
    margin: 12px 0;
    flex: initial;
    width: 90%;

    .project-image {
      display: none;
    }

    .title-container {
      h2 {
        font-size: 1.5em;
      }
    }
  }
}