@import '../../../../assets/variables';

.photo-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh);
  width: 100%;
  padding: 20px 0 20px 0;
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  background-color: rgba(0,0,0, .5);

  .image-container {
    position: relative;
    height: 100%;
    max-width: 100%;
  }

  img {
    height: 100%;
  }
  
  .loading {
    color: $white-navigation-color-hover;
    font-size: 2.5em;
    animation: spin 1s linear infinite;
  }

  .close {
    position: absolute;
    padding: 20px;
    top: 0; right: 0;
    cursor: pointer;
    background-color: rgba(0,0,0, .5);
    
    svg {
      font-size: 1.8em;
      color: $white-navigation-color-hover;
    }
  }

  .exif-data {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, .5);
    margin: 0;
    padding: 12px;
    color: $white-navigation-color-hover;

    ul { 
      margin: 0; 
      padding: 0; 
      list-style-type: none;
    }
    li {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 3px 0;
    }

    .icon {
      display: flex;
      width: 25px;
      justify-content: center;
      margin-right: 6px;

      svg { font-size: 1.2em; }
    }
    .iso-icon { padding: 2px 3px; border-radius: 4px; background-color: black; font-size: .8em; font-weight: bold; }
  }
}

@keyframes spin {
  from { transform: rotate(0); }
  to { transform: rotate(360deg); }
}

@keyframes show {
  from { opacity: 0; }
  to { opacity: 1; }
}

@media only screen and (max-width: 700px) {
  .photo-detail {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
    padding: 80px 0 20px 0;
    box-sizing: content-box;

    .image-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      img {
        width: 120%;
        height: auto;
        margin-left: 10%;
      }
    }

    .close {
      left: 50%;
      transform: translate(-50%, 0);
      background-color: rgba(255,255,255, .4);
      border-radius: 100px;
      padding: 10px 12px;
      width: 52px;
      height: 52px;
      margin-top: 30px;
    }

    .exif-data {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
  }
}