@import '../../assets/variables';
@import '../../assets/mixins';

.options-container {
  position: relative;
  margin-left: auto;

  .options-icon {
    padding: 12px;
    cursor: pointer;
    color: $white-1;
    box-sizing: content-box;
    font-size: 1.5em;
    transition: background-color .3s, color .3s;
  }

  .options-menu {
    position: absolute;
    display: block;
    top: 100%;
    right: 0;
    background-color: $black;
    color: $white-1;
    padding: 0 24px;
    width: 250px;
    height: 0;
    overflow: hidden;
    margin-top: 141px;
    margin-bottom: 141px;
    transition: transform .3s, margin .3s, height .3s;

    .options-content {
      padding: 10px 0 24px 0;
      opacity: 0;
      transition: opacity .3s;
    }

    .range-container {
      position: relative;
      margin: 6px 0;
    }

    .track {
      position: absolute;
      width: 100%;
      top: 4px;
      left: 2px;
      background: linear-gradient(to right, $color-purple, $color-purple-darkest);
      height: 10px;
      border-radius: 3px;

      .track-end {
        position: absolute;
        height: 6px;
        top: 2px;
        right: 2px;
        border-radius: 3px;
        background: $black-1;
      }
    }

    input[type='range'] {
      -webkit-appearance: none;
      width: 100%;
      background: transparent;
      margin-bottom: 6px;
      transform: translate(0,0);

      // Thumb
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        @include range-thumb();
      }
      &::-moz-range-thumb {
        @include range-thumb();
      }
      &::-ms-thumb {
        @include range-thumb();
      }

      // Track
      &::-ms-track {
        cursor: pointer;
        background: transparent; 
        border-color: transparent;
        color: transparent;
      }

      &:focus {
        outline: none;
      }
    }

    .slider-label { display: flex; font-weight: bold; margin-top: 14px; }
    .slider-value { margin-left: auto; }
    .description { font-size: .9em; }
  }

  &.active {
    .options-menu { 
      height: 282px;
      margin: 0;
    }
    .options-content {
      opacity: 1;
    }
    .options-icon {
      background-color: $black;
      color: $white;
    }
  }
}

@media only screen and (max-width: 700px) {
  .options-container { display: none; }
}