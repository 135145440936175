@import '../../assets/variables';

header {
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  align-items: flex-start;
  padding: 20px;
  opacity: 0;

  h1 {
    text-align: center;
    font-size: 2.3em;
    font-family: 'Inconsolata', sans-serif;
    font-weight: 300;
    background-color: $black;
    color: $white;
    padding: 3px;
  }

  h2 {
    font-family: 'Montserrat', sans-serif;
  }

  &.hide {
    animation: fade-out .5s forwards;
    pointer-events: none;
  }
}

@media only screen and (max-width: 700px) {
  header {
    flex-wrap: wrap;
    
    h1 {
      display: inline-block;
      
      order: 1;
    }
  }
}